export type API_ENVIRONMENT = 'dev' | 'staging' | 'prod';

const devProxyLambdaBase = 'https://dt323dleyitqowwrc5svmrzjky0yjvdg.lambda-url.us-east-1.on.aws';
const stagingProxyLambdaBase = '';
const prodProxyLambdaBase = 'https://4dponnthjdqynnumaz6oqzrmse0ylxqb.lambda-url.us-east-1.on.aws';

//
// Configurations
//
let LOCAL = {
  appName: 'Signalwise',
  dev: true,
  apiDebugging: true,

  acctMgmtApi: `${devProxyLambdaBase}/accountManagement`,
  acctMgmtApiV3: `${devProxyLambdaBase}/accountManagementV3`,
  dataApiUrl: `${devProxyLambdaBase}/dataApi`,
  eckleburgApiUrl: `${devProxyLambdaBase}/eckleburgApi`,
  partnersApiUrl: `${devProxyLambdaBase}/partnersApi`,
  pushServiceApiV2Url: `${devProxyLambdaBase}/pushServiceApiV2`,
  logs_lambda_base_url : `${devProxyLambdaBase}logsLambda`,
  homeApiUrl: `${devProxyLambdaBase}/homeApi`,
  snowflakeConnectorUrl: `${devProxyLambdaBase}/snowflakeConnector`,
  frontendApiGateway: 'https://api.dev.ubiety.io/frontend/',
  
  // ubiety keys
  acctMgmtApiKey: '204dc991-aef5-49cd-ba0e-1bdc9150610b',
  partners_api_read_token: '8c8c8a84-e163-44a6-b764-325210d250f9',

  // google
  geofenceApiUrl: 'https://geofence-api-dev.homeaware.io',
  googleApiKey: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  geocodeApiKey: '',
  googlePlacesApiUrl: '',
  googlePlacesApiKey: '',

  // firebase
  FIREBASE_API_KEY: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  FIREBASE_AUTH_DOMAIN: 'homeaware-dev.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-dev.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-dev',
  FIREBASE_STORAGE_BUCKET: 'homeaware-dev.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '962188949377',
  FIREBASE_APP_ID: '1:962188949377:web:9fb9dfe6b35317cbd45a80',
  FIREBASE_MEASUREMENT_ID: 'G-RYDWYC4089',
  
  // aws
  AWS_IDENTITY_POOL_ID: 'us-east-1:03faa1ea-c517-430c-87ba-d1c5c6264088',
  AWS_REGION: 'us-east-1',
  AWS_PUBSUB_ENDPOINT:
    'wss://a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '170448041685',

  // wigle
  wigle_auth_header: '',
  wigle_search_url: '',  

  // assorted & links
  tmsi_law_enforcement_link: '/downloads/tmsi_law_enforcement_guide.pdf',

  // 
  betaRequestURL: '',
  qrCodeProxyUrl: '',
  tmw_endpoint_base: '',

  websocket_base: '',
  sensorAccountId: "170448041685",
  sensorIotEndpoint: "a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com",
};

const DEVELOPMENT = {
  appName: 'Signalwise',
  dev: true,
  apiDebugging: true,

  acctMgmtApi: `${devProxyLambdaBase}/accountManagement`,
  acctMgmtApiV3: `${devProxyLambdaBase}/accountManagementV3`,
  dataApiUrl: `${devProxyLambdaBase}/dataApi`,
  eckleburgApiUrl: `${devProxyLambdaBase}/eckleburgApi`,
  partnersApiUrl: `${devProxyLambdaBase}/partnersApi`,
  pushServiceApiV2Url: `${devProxyLambdaBase}/pushServiceApiV2`,
  logs_lambda_base_url : `${devProxyLambdaBase}logsLambda`,
  homeApiUrl: `${devProxyLambdaBase}/homeApi`,
  snowflakeConnectorUrl: `${devProxyLambdaBase}/snowflakeConnector`,
  frontendApiGateway: 'https://api.dev.ubiety.io/frontend/',
  
  // ubiety keys
  acctMgmtApiKey: '204dc991-aef5-49cd-ba0e-1bdc9150610b',
  partners_api_read_token: '8c8c8a84-e163-44a6-b764-325210d250f9',

  // google
  geofenceApiUrl: 'https://geofence-api-dev.homeaware.io',
  googleApiKey: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  geocodeApiKey: '',
  googlePlacesApiUrl: '',
  googlePlacesApiKey: '',

  // firebase
  FIREBASE_API_KEY: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  FIREBASE_AUTH_DOMAIN: 'homeaware-dev.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-dev.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-dev',
  FIREBASE_STORAGE_BUCKET: 'homeaware-dev.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '962188949377',
  FIREBASE_APP_ID: '1:962188949377:web:9fb9dfe6b35317cbd45a80',
  FIREBASE_MEASUREMENT_ID: 'G-RYDWYC4089',
  
  // aws
  AWS_IDENTITY_POOL_ID: 'us-east-1:03faa1ea-c517-430c-87ba-d1c5c6264088',
  AWS_REGION: 'us-east-1',
  AWS_PUBSUB_ENDPOINT:
    'wss://a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '170448041685',

  // wigle
  wigle_auth_header: '',
  wigle_search_url: '',  

  // assorted & links
  tmsi_law_enforcement_link: '/downloads/tmsi_law_enforcement_guide.pdf',

  // 
  betaRequestURL: '',
  qrCodeProxyUrl: '',
  tmw_endpoint_base: '',

  websocket_base: '',
  sensorAccountId: "170448041685",
  sensorIotEndpoint: "a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com",
};
const STAGING = {
  appName: 'Signalwise',
  dev: false,
  apiDebugging: true,

  acctMgmtApi: `${stagingProxyLambdaBase}/accountManagement`,
  acctMgmtApiV3: `${stagingProxyLambdaBase}/accountManagementV3`,
  dataApiUrl: `${stagingProxyLambdaBase}/dataApi`,
  eckleburgApiUrl: `${stagingProxyLambdaBase}/eckleburgApi`,
  partnersApiUrl: `${stagingProxyLambdaBase}/partnersApi`,
  pushServiceApiV2Url: `${stagingProxyLambdaBase}/pushServiceApiV2`,
  logs_lambda_base_url : `${stagingProxyLambdaBase}logsLambda`,
  homeApiUrl: `${stagingProxyLambdaBase}/homeApi`,
  snowflakeConnectorUrl: `${stagingProxyLambdaBase}/snowflakeConnector`,
  frontendApiGateway: 'https://api.staging.ubiety.io/frontend/',
  
  // ubiety keys
  acctMgmtApiKey: '',
  partners_api_read_token: '',

  // google
  geofenceApiUrl: 'https://geofence-api-staging.homeaware.io',
  googleApiKey: 'AIzaSyAFuTWrgKu6ddgQ87WZBW90LY6tR29hR-M',
  geocodeApiKey: '',
  googlePlacesApiUrl: '',
  googlePlacesApiKey: '',

  // firebase
  FIREBASE_API_KEY: 'AIzaSyAFuTWrgKu6ddgQ87WZBW90LY6tR29hR-M',
  FIREBASE_AUTH_DOMAIN: 'homeaware-staging-2a67e.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-staging-2a67e.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-staging-2a67e',
  FIREBASE_STORAGE_BUCKET: 'homeaware-staging-2a67e.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '67155199558',
  FIREBASE_APP_ID: '1:67155199558:web:bc2b0df410726801f4ac47',
  FIREBASE_MEASUREMENT_ID: 'G-NHX83N3SC2',
  
  // aws
  AWS_IDENTITY_POOL_ID: 'us-east-1:7afd0109-8d6b-4b16-a484-c45de3952ccc',
  AWS_REGION: 'us-east-1',
  AWS_PUBSUB_ENDPOINT:
    'wss://a1wnskyxowv6qs-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '',

  // wigle
  wigle_auth_header: '',
  wigle_search_url: '',  

  // assorted & links
  tmsi_law_enforcement_link: '/downloads/tmsi_law_enforcement_guide.pdf',

  // 
  betaRequestURL: '',
  qrCodeProxyUrl: '',
  tmw_endpoint_base: '',

  websocket_base: '',
  sensorAccountId: "776171956246",
  sensorIotEndpoint: "a1wnskyxowv6qs-ats.iot.us-east-1.amazonaws.com",
};
const PRODUCTION = {
  appName: 'Signalwise',
  dev: false,
  apiDebugging: true,

  acctMgmtApi: `${prodProxyLambdaBase}/accountManagement`,
  acctMgmtApiV3: `${prodProxyLambdaBase}/accountManagementV3`,
  dataApiUrl: `${prodProxyLambdaBase}/dataApi`,
  eckleburgApiUrl: `${prodProxyLambdaBase}/eckleburgApi`,
  partnersApiUrl: `${prodProxyLambdaBase}/partnersApi`,
  pushServiceApiV2Url: `${prodProxyLambdaBase}/pushServiceApiV2`,
  logs_lambda_base_url : `${prodProxyLambdaBase}logsLambda`,
  homeApiUrl: `${prodProxyLambdaBase}/homeApi`,
  snowflakeConnectorUrl: `${prodProxyLambdaBase}/snowflakeConnector`,
  frontendApiGateway: 'https://api.ubiety.io/frontend/',
  
  // ubiety keys
  acctMgmtApiKey: '204dc991-aef5-49cd-ba0e-1bdc9150610b',
  partners_api_read_token: '8c8c8a84-e163-44a6-b764-325210d250f9',

  // google
  geofenceApiUrl: 'https://geofence-api.homeaware.io',
  googleApiKey: 'AIzaSyAK6nXXJw2P2yhKNMJr1C_r7n90mcni-GM',
  geocodeApiKey: '',
  googlePlacesApiUrl: '',
  googlePlacesApiKey: '',

  // firebase
  FIREBASE_API_KEY: 'AIzaSyAK6nXXJw2P2yhKNMJr1C_r7n90mcni-GM',
  FIREBASE_AUTH_DOMAIN: 'homeaware-prod.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-prod.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-prod',
  FIREBASE_STORAGE_BUCKET: 'homeaware-prod.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '59544232385',
  FIREBASE_APP_ID: '1:59544232385:web:d71f484a00a44e9e500f40',
  FIREBASE_MEASUREMENT_ID: 'G-7JKFHMGNRX',
  
  // aws
  AWS_IDENTITY_POOL_ID: 'us-east-1:c0bc379a-053b-4b1e-ac8a-0c05d43fc4d9',
  AWS_REGION: 'us-east-1',
  AWS_PUBSUB_ENDPOINT:
    'wss://a3c3u7bh3lrc3d-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '',

  // wigle
  wigle_auth_header: '',
  wigle_search_url: '',  

  // assorted & links
  tmsi_law_enforcement_link: '/downloads/tmsi_law_enforcement_guide.pdf',

  // 
  betaRequestURL: '',
  qrCodeProxyUrl: '',
  tmw_endpoint_base: '',

  websocket_base: '',
  sensorAccountId: "823885759973",
  sensorIotEndpoint: "a3c3u7bh3lrc3d-ats.iot.us-east-1.amazonaws.com",
};

const returnConfigData = (apiEnv: API_ENVIRONMENT) => {
  if (!process.env.REACT_APP_ENVIRONMENT && process.env.NODE_ENV === 'development') {
    return LOCAL;
  }

  // All othe cases
  switch (apiEnv) {
    case 'dev':
      return DEVELOPMENT;
    case 'staging':
      return STAGING;
    case 'prod':
      return PRODUCTION;
    default:
      return DEVELOPMENT;
  }
};

//
//Amplify
//
// const AWS_AMPLIFY = {
//   AWS_IDENTITY_POOL_ID: 'us-east-1:d4da69ce-05ed-44a6-9d0e-fe4e43933b71',
//   AWS_REGION: 'us-east-1',
//   AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
//   AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
//   AWS_PUBSUB_ENDPOINT:
//     'wss://a229utg519v7kg-ats.iot.us-east-1.amazonaws.com/mqtt',
// };

const API_KEYS = {
  X_API_KEY: '204dc991-aef5-49cd-ba0e-1bdc9150610b',
};

const environment = (process.env.REACT_APP_ENVIRONMENT as API_ENVIRONMENT | undefined) ?? 'dev';

export const ENV = {
  VERSION: '3.3.0',
  environment,
  //...AWS_AMPLIFY,
  ...API_KEYS,

  // ...DEVELOPMENT,
  // ...STAGING,
  // ...PRODUCTION,
  ...returnConfigData(environment),
};
